import React from 'react';
import { node } from 'prop-types';

import Header from './Header';
import Footer from './Footer';

const propTypes = {
  children: node.isRequired,
};

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = propTypes;

export default Layout;
