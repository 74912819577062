import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
// import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="align-items-center justify-content-center py-3">
          <Col xs="auto" className="fs-7">
            © {new Date().getFullYear()}, Presidium Health, All rights reserved
            -{' '}
            <Link
              to="/privacy-policy/"
              className="text-capitalize text-dark text-decoration-underline"
            >
              Privacy Policy
            </Link>
          </Col>
          {/* <Col xs="auto">
            <div className="d-flex align-items-center my-3">
              <a
                href="www.google.com"
                target="_blank"
                rel="noopener noreferrer"
                className="me-2"
              >
                <FaFacebookSquare size={20} className="text-dark" />
              </a>

              <a
                href="www.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin size={20} className="text-dark" />
              </a>
            </div>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
