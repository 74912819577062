import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useScrollDirection } from 'react-use-scroll-direction';

const Header = () => {
  const [scrollDown, setScrollDown] = useState(false);
  const { isScrolling, isScrollingDown, isScrollingUp } = useScrollDirection();
  const navbarClass = cx('justify-content-between', 'bg-white', {
    'scroll-down': scrollDown,
  });

  useEffect(() => {
    if (isScrolling && window?.scrollY > 0) {
      if (isScrollingDown && !scrollDown) {
        setScrollDown(true);
      } else if (isScrollingUp && scrollDown) {
        setScrollDown(false);
      }
    }
  }, [isScrolling, isScrollingDown, scrollDown, isScrollingUp]);

  return (
    <Navbar expand="lg" fixed="top" className={navbarClass}>
      <Container>
        <Navbar.Brand as={Link} to="/">
        <StaticImage
          src="../../images/logo.svg"
          alt="AVneo"
          loading="eager"
          placeholder="none"
          width={181}
          className="logo"
        />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="flex-grow-0 flex-column align-items-end"
        >
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              to="/"
              className="text-uppercase"
              activeClassName="active-link"
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/services/"
              className="text-uppercase"
              activeClassName="active-link"
            >
              Services
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/impact/"
              className="text-uppercase"
              activeClassName="active-link"
            >
              Impact
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact-us/"
              className="text-uppercase"
              activeClassName="active-link"
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
